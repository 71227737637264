@import '../../styles/abstract.scss';

.default {
	position: relative;
	color: $text-gray;
	display: block;
	margin-bottom: 80px;

	@include media-below(md) {
		margin-bottom: 48px;
	}

	&:hover {
		text-decoration: none;
	}

	&.exposed {
		h3 {
			color: $primary-color;
		}
	}

	[class*="loader"] {
		position: absolute;
		height: 100px;
		width: 100px;
		top: 80px;
		right: 0;
		left: 0;
		margin: auto;
	}

	.tag, .actions {
		position: absolute;
		top: 10px;
		z-index: 2;
	}

	.tag {
		left: 10px;
		background-color: $tag-background;
		padding: 7px 10px;
		color: $white;
		text-transform: uppercase;
		font-size: 12px;
		@include border-radius(3px);
		font-weight: $font-bold;
	}

	.actions {
		right: 10px;
		@include flexbox();

		button, a {
			background: transparent;
			cursor: pointer;
			@include border-radius(5px);
			box-shadow: 0px 3px 6px #00000029;
			background-color: $white;
			// padding: 7px;
			height: 30px;
			width: 30px;
			border: 0;
			text-align: center;
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);

			&.active {
				background-color: $success-color;
			}

			i {
				&[class*="heart"] { @include icon-dimension-only(18, 16); }
				&[class*="bell-on"] { @include icon-dimension-only(16, 13); }
				&[class*="pen"] { @include icon-dimension-only(16, 16); }
			}

			&:not(:last-child) {
				margin-right: 5px;
			}
		}
	}

	.imagePlaceholder {
		display: block;
		// @include background-image-only('');
		background-image: url('/img/drazba-placeholder-grid.svg');
		width: 100%;
		background-size: cover;
		margin-bottom: 16px;
		min-height: 230px;
		position: relative;
		z-index: 1;

		@include media-below(sm) {
			min-height: 247px;
		}
	}

	img {
		@include border-radius(6px);
		width: 100%;

		&.lazy {
			@include opacity(0);
			@include transition(opacity, .3s);

			&.loaded {
				@include opacity(100);
			}
		}
	}

	h4 {
		font-size: 12px;
		line-height: 12px;
		font-weight: $font-semibold;
		margin-bottom: 5px;
	}

	h3 {
			font-size: 17px;
			line-height: 20px;
			font-weight: $font-semibold;
			margin-bottom: 0;
			@include transition(color, .3s);
	}

	p {
		color: $text-gray-light;
		font-size: 12px;
		line-height: 20px;
		margin: 8px 0 10px 0;
	}

	.data {
		a {
			text-decoration: none;
			color: inherit;
		}
	}

	.bottom {
		margin-top: 30px;

		button {
			width: 100%;
		}
	}

	.cols {
		@include flexbox();
		@include justify-content(flex-start);

		dl {
			dt {
				font-size: 12px;
				line-height: 100%;
				margin-bottom: 8px;
			}

			dd {
				font-weight: $font-semibold;
			}

			&:not(:last-of-type) {
				border-right: 1px solid $border-color-light;
			}

			&:not(:first-of-type) {
				padding-left: 30px;
			}

			&:first-of-type {
				padding: 0 30px 0 0;
			}
		}
	}

	&.list {
		@include flexbox();
		margin-bottom: 15px;

		> div {
			position: relative;

			img {
				margin-bottom: 0;
			}
		}

		.data {
			margin-left: 30px;
			padding: 15px 0;
		}
	}

	&.boxRe {
		.data {
			margin-top: 16px;
		}
	}
}
