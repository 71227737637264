@import '../../styles/abstract.scss';

.default {
	text-align: center;
	width: 100%;
	margin-top: 50px;

	h2 {
		font-size: 20px!important;
		line-height: 30px;
		font-weight: $font-semibold;
		margin: 0!important;
		display: block!important;
	}

	p {
		font-size: 15px;
		line-height: 20px;
		margin: 10px 0 20px;
	}

	a {
		display: inline-block;
		margin-top: 10px;
	}
}
