@import '../../styles/abstract.scss';

.default {
	@include flexbox();
	text-align: center;
	@include flex-direction(column);
	@include justify-content(center);
	width: 100%;

	p {
		font-size: 10px;
		color: $primary-color;
	}

	&.justifyCenter {
		@include align-items(center);
	}

	&.alignPageCenter {
		height: 100vh;
		@include align-items(center);
	}
}

.line {
	position: absolute;
	top: 0;
  width: 100%;
  height: 3px;
  overflow: hidden;
  background-color: $white;

	&:before{
	  display: block;
	  position: absolute;
	  content: "";
	  left: -200px;
	  width: 200px;
	  height: 4px;
	  background-color: $primary-color;
	  -webkit-animation: loading 2s linear infinite;
	  animation: loading 2s linear infinite;
	}
}

@keyframes loading {
  from 	{ left:-200px; width:30%; }
  50% 	{ width: 30%; }
  70% 	{ width: 70%; }
  80% 	{ left: 50%; }
  95% 	{ left: 120%; }
  to 		{ left: 100%; }
}

@-webkit-keyframes loading {
  from 	{ left:-200px; width:30%; }
  50% 	{ width: 30%; }
  70% 	{ width: 70%; }
  80% 	{ left: 50%; }
  95% 	{ left: 120%; }
  to 		{ left: 100%; }
}

.threeDots {
	display: inline-block;
}

.contentPlaceholder {
  margin: 0 auto;
  width: 100%;
  min-height: 200px;
	background-color: $white;
	padding: 12px;
	background-color: #fff;
	border: 1px solid $border-color-ligher;

	.animatedBackground {
	  animation-duration: 3s;
	  animation-fill-mode: forwards;
	  animation-iteration-count: infinite;
	  animation-timing-function: linear;
	  animation-name: placeHolderShimmer;
	  background: #f6f7f8;
	  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	  background-size: 800px 104px;
	  height: 550px;
	  position: relative;
		overflow: hidden;

		@include media-below(md) {
			height: 350px;
		}
	}

	.backgroundMasker {
		position: absolute;
	  background: $white;
	}

	.title {
		height: 10px;
		width: 100%;
		top: 40px;
	}

	.subtitleRight {
		left: 60%;
		height: 30px;
		width: 40%;
		top: 43px;
	}

	.gallery {
		top: 73px;
		height: 15px;
		width: 100%;
	}

	.galleryRight {
		top: 88px;
		left: 70%;
		height: 15px;
		width: 15px;
		height: 465px;
	}

	.aside {
		height: 500px;
		width: 100%;
	}
}


@keyframes placeHolderShimmer {
	0%{ background-position: -1280px 0; }
	100%{ background-position: 1280px 0; }
}

/**
 * ==============================================
 * Dot Typing - https://github.com/nzbin/three-dots
 * ==============================================
 */

$dot-width: 6px !default;
$dot-height: 6px !default;
$dot-radius: 3px !default;

// White
$dot-color: $white !default;
$dot-bg-color: $dot-color !default;
$dot-before-color: $dot-color !default;
$dot-after-color: $dot-color !default;

// Primary
$dot-color-primary: $primary-color !default;
$dot-bg-color-primary: $dot-color-primary !default;
$dot-before-color-primary: $dot-color-primary !default;
$dot-after-color-primary: $dot-color-primary !default;

$dot-spacing: $dot-width + $dot-radius !default;

$left-pos: -9999px;
$x1: -$left-pos - $dot-spacing;
$x2: -$left-pos;
$x3: -$left-pos + $dot-spacing;

.dotTyping {
	display: inline-block;
  position: relative;
  left: $left-pos;
  width: $dot-width;
  height: $dot-height;
  border-radius: $dot-radius;
  background-color: $dot-bg-color;
  color: $dot-color;
  box-shadow:
    $x1 0 0 0 $dot-before-color,
    $x2 0 0 0 $dot-color,
    $x3 0 0 0 $dot-after-color;

	&.white {
		animation: dot-typing-white 1.5s infinite linear;
	}

	&.primary {
		animation: dot-typing-primary 1.5s infinite linear;
		background-color: $dot-bg-color-primary;
	  color: $dot-color-primary;
	  box-shadow:
	    $x1 0 0 0 $dot-before-color-primary,
	    $x2 0 0 0 $dot-color-primary,
	    $x3 0 0 0 $dot-after-color-primary;
	}
}

@keyframes dot-typing-white {
  0% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }

  16.667% {
    box-shadow:
      $x1 -10px 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }

  33.333% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }

  50% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 -10px 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }

  66.667% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }

  83.333% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 -10px 0 0 $dot-after-color;
  }

  100% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }
}

@keyframes dot-typing-primary {
  0% {
    box-shadow:
      $x1 0 0 0 $dot-before-color-primary,
      $x2 0 0 0 $dot-color-primary,
      $x3 0 0 0 $dot-after-color-primary;
  }

  16.667% {
    box-shadow:
      $x1 -10px 0 0 $dot-before-color-primary,
      $x2 0 0 0 $dot-color-primary,
      $x3 0 0 0 $dot-after-color-primary;
  }

  33.333% {
    box-shadow:
      $x1 0 0 0 $dot-before-color-primary,
      $x2 0 0 0 $dot-color-primary,
      $x3 0 0 0 $dot-after-color-primary;
  }

  50% {
    box-shadow:
      $x1 0 0 0 $dot-before-color-primary,
      $x2 -10px 0 0 $dot-color-primary,
      $x3 0 0 0 $dot-after-color-primary;
  }

  66.667% {
    box-shadow:
      $x1 0 0 0 $dot-before-color-primary,
      $x2 0 0 0 $dot-color-primary,
      $x3 0 0 0 $dot-after-color-primary;
  }

  83.333% {
    box-shadow:
      $x1 0 0 0 $dot-before-color-primary,
      $x2 0 0 0 $dot-color-primary,
      $x3 -10px 0 0 $dot-after-color-primary;
  }

  100% {
    box-shadow:
      $x1 0 0 0 $dot-before-color-primary,
      $x2 0 0 0 $dot-color-primary,
      $x3 0 0 0 $dot-after-color-primary;
  }
}
