@import '../../styles/abstract.scss';

.items {
	.dropdowns {
		@include flexbox();
		// width: 100%;
		position: relative;
		// z-index: 1000;

		@include media-below(md) {
			@include flex-direction(column);
		}

		> * {
			margin-right: 10px;

			@include media-below(md) {
				margin: 0;
				border-right: 0 !important;
			}

			+ * {
				@include media-below(md) {
					//margin-top: 5px;
				}
			}
		}
	}
}

.default {
	padding: 20px 0 30px 0;
	@include flex-direction(column);

	@include media-above(sm) {
		position: relative;
		z-index: 1;
	}

	@include media-below(md) {
		width: 100%;
		padding: 0;
	}

	select {
		border: 1px solid $border-color-light;
		@include border-radius(5px);
		height: 40px;
		padding: 0 35px 0 20px;
		background-position: 90% center;
		min-width: 170px;

		@include media-below(md) {
			background-position: 95% center;
		}

		&[class*="active"] {
			@include active-filter();
		}
	}

	[class^="select"] {
		min-width: 200px;

		@include media-below(lg) {
			min-width: 150px;
		}

		[class*="control"] {
			border: 1px solid $border-color-light;

			&:hover {
				border-color: inherit;
			}
		}

		&[class*="active"] {
			[class*="control"] {
				@include active-filter();
			}

			[class*="indicatorContainer"] {
				&:after {
					@include icon-image-only('chevron-down-regular.svg');
				}
			}
		}
	}

	[class*="multi-select"] {
		min-width: 230px;
		max-width: 230px;

		@include media-below(lg) {
			min-width: 150px;
			max-width: 150px;
		}

		@include media-below(md) {
			min-width: 100%;
			max-width: 100%;
		}

		&[class*="active"] {
			[class*="dropdown-container"] {
				@include active-filter();
				background-image: url('#{$icon-path}chevron-down-regular.svg');
			}
		}
	}

	/*
	a {
		min-height: 42px;
	}
	*/

	.filters {
		> * {
			margin-right: 10px;
		}
	}

	> .tags {
		.clearAll {
			margin: 0 5px 5px 0;
			font-size: 12px;
			line-height: 15px;
			color: $primary-color;
		}

		&:first-child {
			@include media-below(md) {
				@include flex-direction(column);
			}

			> * {
				@include media-above(md) {
					margin-right: 10px;
				}

				margin-bottom: 10px;
			}
		}
	}
}

.locked {
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	background: rgba(255,255,255,0.5);
	width: 100%;
	height: 100%;
}

.filtersActions {
	@include flex-wrap(wrap);

	> *:not(:last-child) {
		margin-right: 10px;
	}
}

.hero {
	width: 100%;

	.dropdowns {
		width: 100%;

		> * {
			margin-right: 0;
		}

		select option:checked, [class*="singleValue"] {
			font-weight: $font-medium;
			color: $text-form-selected!important;
		}
	}

	.items {
		width: 100%;
		box-shadow: 0px 3px 20px #0000001A;
		@include border-radius(5px);
		background-color: $white;
		@include flexbox();
		@include justify-content(space-between);

		> button {
			min-width: 125px;
		}

		@include media-below(md) {
			flex-direction: column;
			margin: 0 auto;
		}
	}

	.item {
		padding: 0 15px;
		border-right: 1px solid $border-color-light;
		@include flexbox();
		@include align-items(space-between);
		min-height: 50px;

		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3) {
			width: 100%;

			@include media-below(md) {
				width: 100%;
			}
		}

		&:nth-child(3) {
			border-right: 0;
		}

		[class*="select"] {
			width: 100%;

			[class*="control"] {
				height: 52px;
				padding: 0;
			}
		}
	}

	button {
		width: 10%;
		margin: 6px;
		text-align: center;

		@include media-below(md) {
			width: calc(100% - 12px);
			padding: 15px;
		}

		@include media-below(md) {
			margin: 10px;
			width: calc(100% - 20px);
		}
	}
}

.filtersCloser {
	position: fixed;
	z-index: 11;
	top: 0;
	left: 0;
	border: 0;
	background-color: transparent;
	width: 100%;
	height: 100%;
}

.filters {
	@include flexbox();
	position: relative;
	z-index: 12;

	@include media-below(md) {
		@include flex-direction(column);
		margin-top: 0;
	}

	[class*="btn"]:not(.reset) {
		padding: 11px 17px;
		font-weight: 400;
	}

	button[class*="active"] {
		@include active-filter();
	}

	.sectionWrapper {
		@media (max-height: 1000px) {
			@include media-above(md) {
				max-height: 50vh;
				overflow: auto;
			}

			@include scrollbar();
		}
	}

	.box {
		position: absolute;
		background-color: $white;
		box-shadow: 0px 3px 20px #00000029;
		@include border-radius(5px);
		padding: 20px;
		right: auto; // 0
		top: 110%;
		z-index: 2;
		width: 440px;
		display: none;

		@include media-below(lg) {
			left: 0;
		}

		section {
			&:not(:nth-of-type(1)) {
				border-top: 1px solid $section-separator-color;
				padding-top: 20px;
				margin-top: 20px;
			}

			&.borderBottom {
				border-bottom: 1px solid $section-separator-color;
				padding-bottom: 20px;
				margin-bottom: 20px;
			}

			.label {
				max-width: 90%;
			}

			h4 + [class*="checkbox"] {
				margin-top: 20px;
			}

			[class*="checkbox"] {
				[class*="input"] {
					@include order(2);
					margin: 0;
				}
			}

			p {
				font-size: 14px;

				& + p {
					color: $text-gray-light;
					margin-top: 5px;
					font-size: 12px;
				}
			}
		}
	}

	[class*="checkbox"] {
		@include flexbox();
		@include justify-content(space-between);

		&+[class*="checkbox"] {
			margin-top: 24px;
		}
	}

	h4 {
		color: $gray-dark;
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: $font-medium;
	}

	.buttons {
		@include flexbox();
		@include justify-content(space-between);

		@include media-above(md) {
			margin-top: 20px;
		}

		&.alignRight {
			@include justify-content(flex-end);
		}

		[class*="plain"] {
			padding: 8px 0;
		}
	}

	&.open {
		.box {
			display: block;
		}
	}
}

.items {
	@include flexbox();
	width: 100%;

	select {
		@include media-below(md) {
			margin-bottom: 0;
		}
	}
}

.tags {
	@include flexbox();
	@include flex-wrap(wrap);
	width: 100%;
	margin-top: 20px;
}

.mobile {
	@include flex-direction(column);
	position: fixed!important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
	z-index: 100;
	background-color: $white;

	.item {
		display: block;
		width: 100%;
	}

	.top {
		position: relative;
		padding: 7px;
		border-bottom: 1px solid $border-color-light;
		@include flexbox();
		@include justify-content(center);

		@include media-below(md) {
			padding: 15px;
		}

		button {
			margin-left: 0;
			height: 12px;
			width: 12px;
			position: absolute;
			left: 20px;
			top: 0;
			bottom: 0;
			margin: auto;

			i {
				top: -5px;
			}
		}

		h2 {
			line-height: 1;
		}
	}

	.body {
		height: calc(100vh - 123px);
		overflow: auto;

		.item {
			padding: 25px 20px;

			.list {
				display: none;
			}

			button {
				width: 100%;

				h4 {
					position: relative;
					width: 100%;
					text-align: left;

					&:after {
						content: "";
						position: absolute;
						display: block;
						@include transition(all, .15s, ease-in-out);
						@include icon('chevron-down-solid.svg', 12, 6);
						right: 0;
						top: 5px;
					}
				}

				 + p {
					 &:not(:empty) {
						 display: block;
						 padding-top: 20px;
					 }
				 }

				&.active {
					h4 {
						&:after {
							@include rotate(180);
						}
					}

					+ p {
						display: none;

						+ .list {
							display: block;
						}
					}
				}
			}

			&:not(:first-child) {
				border-top: 1px solid $border-color-light;
			}

			[class*="radiobutton "], [class*="checkbox "] {
				margin-top: 24px;
			}

			> div > i {
				margin-top: 20px;
				display: inline-block;
			}
		}
	}

	.bottom {
		@include flexbox();
		@include justify-content(space-between);
		box-shadow: 0px 3px 20px #C8D2DD70;
		padding: 16px 20px;

		@include media-below(md) {
			min-height: 72px;
		}

		.btn:not([class*="plain"]) {
			padding: 15px;
		}

		button[class*="plain"] {
			color: $text-gray-light;
		}
	}

	.filters {
		.box {
			position: relative;
			display: block;
			left: auto;
			top: auto;
			width: 100%;
			padding: 0;
			box-shadow: none;
			padding: 0px;
		}
	}
}
